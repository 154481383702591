<template>
	<div style="">
		<div class="auth-icon-con">
			<div class="auth-icon-az">
				<img src="../../../assets/images/amazon/800px-Amazon_logo.png" width="150" height="auto" />
			</div>
			<div class="auth-icon-arrow">
				<img src="../../../assets/images/amazon/arrow-bothway.png" width="30" height="auto" />
			</div>
			<div class="auth-icon-hytx">
				<img :src="$store.state.sysShow.logoInfo.logo" width="150" height="auto" />
			</div>
		</div>
		<div v-if="loading_auth">
			
			<div class="step-title">
				<i class="el-icon-loading"></i>
				<span>正在授权..(请勿刷新此页面)</span>
			</div>
			
			<!-- <div class=""> -->
				<!-- <div class="step-title">
					<i class="el-icon-check"></i>
					<span>{{$t('i18nn_c5f937578ab0c290')}}</span>
				</div> -->
				
				<!-- <div class="step-msg">
					<span>授权成功后自动 跳转到 授权列表 页面..</span>
					<el-button type="primary" icon="el-icon-back" @click="$router.push({name:'amazonUserList'})">{{$t('i18nn_d9e17eb65f5720f7')}}</el-button>
				</div> -->
				
			<!-- </div> -->
		</div>
		
		
		
		
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				
				// authData:{},
				loading_auth: false,
				// authSuccess:"",
				// loading_load: false,
				// tableData: [],
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 10, 20, 30, 100, 200, 500],
				// 	page_size: 10,
				// 	total: 0
				// },
				selectOption: {},
				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc' //desc降序，asc升序
				// }
			}
		},
		mounted() {
			this.initData();
		},
		methods:{
			initData() {
				// this.pagination.current_page = 1;
				// this.getPageData();
				let sellingPartnerId = this.$route.query.selling_partner_id;
				let mwsAuthToken = this.$route.query.mws_auth_token;
				let spapiOauthCode = this.$route.query.spapi_oauth_code;
				if (sellingPartnerId && spapiOauthCode) {
					this.amazonSpAuthData(sellingPartnerId, mwsAuthToken, spapiOauthCode);
					//设置 amazon spid
          // this.$store.dispatch('UPDATE_SPID', sellingPartnerId);
				} else {
					this.$alert(this.$t('i18nn_10e868dfd9e69b86'), this.$t('tips.tipsTitle'), {
					});
				}
			},
			
			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	// this.getPageData();
			// },
			//授权
			amazonSpAuthData(sellingPartnerId, mwsAuthToken, spapiOauthCode) {
				// let _this = this;
				this.loading_auth = true;
				this.$http
					.put(this.$urlConfig.amazonSpAuth, {
						sellingPartnerId: sellingPartnerId, //"店铺id",
						mwsAuthToken: mwsAuthToken, //"",
						spapiOauthCode: spapiOauthCode //"授权code"
					})
					.then(({ data }) => {
						console.log('分页，请求成功');
						console.log(data);
						this.loading_auth = false;
						// this.authData = data;
						if ('200' == data.code) {
							this.$message.success(this.$t('i18nn_c5f937578ab0c290'));
							// this.$alert("授权成功，正在跳转", this.$t('tips.tipsTitle'), {
							// });
							// this.getPageData();
							this.$router.push({name:"amazonUserList"});
						} else {
							// this.$message.warning("授权失败，请关闭重试");
							this.$alert("授权失败，请关闭重试", this.$t('tips.tipsTitle'), {
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						// this.$message.warning("授权失败，请关闭重试!");
						this.$alert("授权失败，请关闭重试!", this.$t('tips.tipsTitle'), {
						});
						this.loading_auth = false;
					});
			},
			//请求分页数据
			// getPageData() {
			// 	// let _this = this;
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.amazonAuthPageList, {
			// 			sortAsc: this.filterData.sortAsc,
			// 			orderBy: this.filterData.orderBy,
			// 			offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 			limit: this.pagination.page_size //当前页显示数目
			
			// 			// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null
			// 		})
			// 		.then(({ data }) => {
			// 			console.log(this.$store.getters.getUserInfo);
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading_load = false;
			// 			//表格显示数据
			// 			this.tableData = data.rows;
			// 			//当前数据总条数
			// 			this.pagination.total = parseInt(data.total);
						
			// 			// setTimeout(()=>{
			// 			// 	window.close();
			// 			// },3000)
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading_load = false;
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_size_unit'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
		
	}

</script>
<style lang="less" scoped>
	.step-con {
		line-height: 150%;
	}
	.step-title {
		font-size: 32px; 
		padding: 10px 0;
		text-align: center;
	}
	.step-loading-icon {
		font-size: 24px; 
		padding: 10px 0;
	}
	.step-msg {
		font-size: 18px; 
		padding: 10px 0;
		text-align: center;
	}
	.auth-icon-con {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		.auth-icon-az {
			padding: 0 20px;
		}
		.auth-icon-arrow {
			padding: 0 20px;
		}
		.auth-icon-hytx {
			padding: 0 20px;
		}
	}
</style>
